<template>
  <div>
    <div class="card">
      <div class="title">
        检验微生物信息
      </div>
      <div class="list">
        <div class="grid-table">
          <div>检查科室</div>
          <div>{{ deptName }}</div>
          <div>患者姓名</div>
          <div>{{ patientName }}</div>
          <div>开单医生</div>
          <div>{{ doctorName }}</div>
          <div>报告时间</div>
          <div>{{ checkTime }}</div>
          <br />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="title" style="text-align: center">
        检验单号:{{ search.InspectID }}
      </div>
      <div v-for="(item, index) in list" :key="index" class="list">
        <div
          class="grid-cont  ainer-title"
          style="border-bottom: 1px solid #dfdfdf"
        >
          {{ item.title }}
        </div>
        <div
          v-for="(itemS, indexS) in item.inspectWSWInfoList"
          :key="indexS"
          class="grid-container"
          style="border-bottom: 1px solid #dfdfdf"
        >
          <div class="grid-item">{{ itemS.microDataID2Name }}</div>
          <div class="grid-item">{{ itemS.microDataID2Result1 }}</div>
          <div class="grid-item">{{ itemS.microDataID2Result2 }}</div>
          <div class="grid-item">{{ itemS.resultMethod }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Base64 from '@/util/Base64'
  import { Toast } from 'vant'
  import { getInspectWSWInfo } from '@/api/lis/lis'

  export default {
    name: 'InspectWSWInfo',
    data() {
      return {
        id: null,
        loading: false,
        formData: {},
        search: {
          InspectID: null,
        },
        list: [],
        deptName: '',
        patientName: '',
        checkTime: '',
        DoctorName: '',
      }
    },
    created() {
      let query = this.$route.query
      this.search.InspectID = this.$Base64.decode(query.inspectionID)
      this.deptName = this.$Base64.decode(query.deptName)
      this.patientName = this.$Base64.decode(query.patientName)
      this.checkTime = this.$Base64.decode(query.checkTime)
      this.doctorName = this.$Base64.decode(query.doctorName)
      this.fetchData()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getInspectWSWInfo(this.search)
        console.log(data)
        this.list = data
        Toast.clear()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-button--info {
    width: 95%;
    margin: 2.5%;
  }
  .card {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    font-family: 'Microsoft Yahei';
    .des {
      text-align: center;
    }
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px 15px 0 15px;
      .van-cell {
        padding: 0.3rem 0;
        overflow: hidden;
        font-size: 0.4rem;
        overflow: hidden;
        ::v-deep .van-cell__value--alone {
          display: flex;
          align-items: center;
          justify-content: space-around;
        }

        span {
          margin-right: 1em;
        }

        span:nth-child(2) {
          font-weight: bold;
        }
        span:nth-child(3) {
          font-weight: bold;
          color: #1691fe;
        }

        .number {
          background: #1691fe;
          width: fit-content;
          padding: 0 15px;
          color: #fff;
          line-height: 30px;
          border-radius: 15px;
          float: right;
          white-space: nowrap;
        }
        .gray {
          background: #bebebe;
        }
      }
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
  .grid-container {
    display: grid;
    grid-template-columns: 45% 20% 20% 15%;
    padding: 0.3rem 0;
  }
  .grid-container-title {
    display: grid;
    padding: 0.3rem 0;
  }
</style>
